import schemaecrf from "@content/tutorial/images/schema-ecrf.png";
import acceseditionetude from "@content/advanced/images/acces-edition-etude.png";
import ajouterphase from "@content/advanced/images/ajoutphase.png";
import creeretape from "@content/advanced/images/creeretape.png";
import ajoutmodele from "@content/advanced/images/ajoutmodele.png";
import editionetape from "@content/advanced/images/editionetape.png";

import { PageLayout, Section, Note } from "@components";

import "@styles/Page.scss";

export default function FeatSynopsis() {
  return (
    <PageLayout title="Study Synopsis" roles={["admin"]}>
      <p>
        The synopsis corresponds to the <b>CRF</b> of your study, consisting of{" "}
        <b>phases</b> and <b>steps</b>:
        <li>
          <b>Phase:</b> a specific period of the study that can contain one or
          more steps and be repeated
        </li>
        <li>
          <b>Steps:</b> a sub-section of a phase that can contain a template and
          be repeated
        </li>
      </p>
      <Section
        id="understanding-ecrf-functionality"
        title="Understanding the Functionality of an eCRF on Dotter.science"
      >
        <p>
          On Dotter.science, the eCRF is a set of <b>forms</b> integrated into
          each phase of the study under the term "<b>form templates</b>". Thus,
          you can create standard forms that can be used multiple times at
          different points in the study without having to recreate them each
          time.
        </p>
        <p>
          The forms themselves contain a series of questions or measurements
          referred to here as "<b>variables</b>". These variables, like the
          forms, are stored in a database under the term "
          <b>variable templates</b>" and can be reused at various points in the
          study.
        </p>
        <img
          src={schemaecrf}
          alt="eCRF Diagram"
          className="fullwidth"
          style={{ boxShadow: "none" }}
        />
        <Note type="important">
          To build the synopsis of your study, you must have previously created
          the variables and form templates for your study. If not, you can refer
          to the help on <a href="/en/advanced/variables">variables</a> and{" "}
          <a href="/en/advanced/models">form templates</a> or follow the
          step-by-step tutorial{" "}
          <a href="/en/tutorial/a-to-z">Designing a Study from A to Z</a>.
        </Note>
      </Section>
      <Section
        id="accessing-study-synopsis-editing"
        title="Accessing the Editing of the Study Synopsis"
      >
        <p>
          To access the study synopsis, go to <b>Design</b> and then{" "}
          <b>Study Editing</b>.
        </p>
        <img
          src={acceseditionetude}
          alt="Study Editing Tab"
          className="centered"
        />
      </Section>
      <Section id="creating-a-phase" title="Creating a Phase">
        <p>
          To create a phase, simply click <b>Add a Phase</b> (1).
        </p>
        <p>
          <li>Choose a name for your phase (2)</li>
          <li>Select a type of timing (3)</li>
          <li>Enter your phase index (4)</li>
          <li>
            You can create a repeatable phase if you wish and indicate the
            number of repetitions (5)
          </li>
          <li>Save your changes by clicking "Add" (6)</li>
        </p>
        <img src={ajouterphase} alt="Create a Phase" className="centered" />
      </Section>
      <Section id="creating-a-step" title="Creating a Step">
        <p>
          To create a step, click <b>Add a Step</b> and assign a name to your
          step.
        </p>
        <img src={creeretape} alt="Create a Step" className="centered" />
        <p>
          You then have the choice between <b>Creating a New Template</b> (which
          will redirect you to the template editing page) and{" "}
          <b>Using an Existing Template</b>.
        </p>
        <img src={ajoutmodele} alt="Choose a Template" className="centered" />
        <p>
          Once you have associated a template with your step, you will see an
          overview of the different phases and variables it contains. Click{" "}
          <b>Add</b> (1) to save your new step. (You can change the associated
          template (2) and edit the current template (3)).
        </p>
        <img src={editionetape} alt="Edit the Step" className="centered" />
      </Section>
    </PageLayout>
  );
}
